<template>
  <div class="frbox">
    <froala
      :onManualControllerReady="initialize"
      :config="config"
      v-model="editorData"
    ></froala>
  </div>
</template>
<script>
import { apiUrl } from "@/constants/config";
import { mapActions } from "vuex";
export default {
  name: "froala-editor",
  props: {
    oldEditorData: { type: String },
    editorDisabled: { type: Boolean },
  },
  data() {
    const that = this;
    return {
      timeout: null,
      initControls: null,
      showEditor: false,
      deleteAll: null,
      enableEditor: null,
      // disableEditor: null,
      form: {
        src: null,
        _method: "DELETE",
      },
      destroyEditor: () => {
        this.initControls.destroy();
        this.showEditor = false;
      },
      createEditor: () => {
        this.initControls.initialize();
        this.showEditor = true;
        setTimeout(() => {
          if (this.editorDisabled) {
            this.disableEditor();
          }
          //default state is disabled
        }, 100);
      },
      initialize: (initControls) => {
        this.initControls = initControls;
        this.deleteAll = () => {
          this.initControls.getEditor().html.set("");
          this.initControls.getEditor().undo.reset();
          this.initControls.getEditor().undo.saveStep();
        };
        this.enableEditor = () => {
          this.initControls.getEditor().edit.on();
        };
        this.disableEditor = () => {
          this.initControls.getEditor().edit.off();
        };
      },
      config: {
        key:
          "7MD3aB3C2C4C3A2F2xROKLJKYHROLDXDRE1b1YYGRi1Bd1C4F4B3B2G3A14A16A12C4C1==",
        charCounterCount: true,
        pastePlain: true,
        attribution: false,
        imagePaste: true,
        imagePasteProcess: true,
        imageResize: true,
        imageEditButtons: [
          "imageDisplay",
          "imageAlign",
          "imageInfo",
          "imageRemove",
        ],
        fileUploadURL: apiUrl + "/memo/froala",
        imageUploadURL: apiUrl + "/memo/froala",
        fileAllowedTypes: [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "image/jpeg",
          "image/png",
        ],
        fileUploadMethod: "POST",
        fileMaxSize: 10 * 1024 * 1024, //10 megabyte
        imageMaxSize: 0.4 * 1024 * 1024, //400 KB
        fileUploadParams: { id: "my_editor" },
        requestHeaders: {
          custom_header: "My custom header data.",
        },
        requestWithCredentials: false,
        crossDomain: true,
        events: {
          keydown: function (keyDownEvent) {
            if (
              [
                8,
                13,
                32,
                46,
                48,
                49,
                50,
                51,
                52,
                53,
                54,
                55,
                56,
                57,
                65,
                65,
                66,
                67,
                68,
                69,
                70,
                71,
                72,
                73,
                74,
                75,
                76,
                77,
                78,
                79,
                80,
                81,
                82,
                83,
                84,
                85,
                86,
                87,
                88,
                89,
                90,
                96,
                97,
                98,
                99,
                100,
                101,
                102,
                103,
                104,
                105,
                106,
                107,
                108,
                109,
                110,
                111,
                186,
                187,
                188,
                198,
                190,
                191,
                192,
                219,
                220,
                221,
                222,
              ].includes(keyDownEvent.keyCode)
            ) {
              that.$emit("enableAction", false);
            }
          },
          "image.beforeUpload": function () {
            var editor = this;
            let myLocalStorage = JSON.parse(window.localStorage.getItem('ELEMEN4'));
            let token = myLocalStorage.user.token;

            editor.opts.requestHeaders.authorization = token;
            editor.popups.hideAll();
            that.axiosOnProgress(true);
          },
          "image.uploaded": function () {
            that.axiosOnProgress(false);
            //  that.$swal({
            //   title: that.$t("msgbox.upload-image-title"),
            //   html:
            //     that.$t("msgbox.upload-image-success") +
            //     " : " +
            //     that.filenameResponse(response) +
            //     "<br>" +
            //     that.$t("msgbox.draft-auto-saved"),
            //   icon: "success",
            //   showCancelButton: false,
            // });
          },
          "image.inserted": function (response) {
            if (response) {
              setTimeout(() => {
                that.$emit("saveOnMe", true);
              }, 500);
            }
          },
          "image.beforeRemove": function (response) {
            let fullPath = response[0].currentSrc;
            that.deleteFile(that.filename(fullPath));
          },
          "image.removed": function (response) {
            if (response) {
              setTimeout(() => {
                that.$emit("saveOnMe", true);
              }, 500);
            }
          },
          "image.error": function (error) {
            that.axiosOnProgress(false);
            if (error.code == 5) {
              console.error("File Gambar terlalu besar");
              that.$swal({
                title: that.$t("msgbox.upload-image"),
                html: that.$t("msgbox.max-upload-size") + " 400 KB",
                icon: "error",
                showCancelButton: false,
              });
            }
            // Invalid file type.
            else if (error.code == 6) {
              console.error("Format file tidak sesuai syarat");
              that.$swal({
                title: that.$t("msgbox.upload-file-title"),
                html: that.$t("msgbox.upload-file-fail-format") + " PNG",
                icon: "error",
                showCancelButton: false,
              });
            }
          },
        },
        tableInsertHelper: false,
        tableInsertButtons: ["tableBack", "|"],
        tableStyles: {
          "fr-dashed-borders": this.$t("menu.froala.dashed-border"),
          "fr-alternate-rows": this.$t("menu.froala.alternate-rows"),
          "black-border": this.$t("menu.froala.black-border"),
          "white-border": this.$t("menu.froala.white-border"),
        },
        tableCellMultipleStyles: false,
        tableCellStyles: {
          "fr-highlighted": this.$t("menu.froala.highlighted"),
          "fr-thick": this.$t("menu.froala.thick"),
        },
        tableColors: [
          "#61BD6D",
          "#1ABC9C",
          "#54ACD2",
          "#2C82C9",
          "#9365B8",
          "#475577",
          "#CCCCCC",
          "#41A85F",
          "#00A885",
          "#3D8EB9",
          "#2969B0",
          "#553982",
          "#28324E",
          "#000000",
          "#F7DA64",
          "#FBA026",
          "#EB6B56",
          "#E25041",
          "#A38F84",
          "#EFEFEF",
          "#FFFFFF",
          "#FAC51C",
          "#F37934",
          "#D14841",
          "#B8312F",
          "#7C706B",
          "#D1D5D8",
          "REMOVE",
        ],
        tableColorsButtons: ["tableBack", "|"],
        tableColorsStep: 7,
        tableDefaultWidth: "100%",
        tableEditButtons: [
          "tableHeader",
          "tableRemove",
          "|",
          "tableRows",
          "tableColumns",
          "tableStyle",
          "-",
          "tableCells",
          "tableCellBackground",
          "tableCellVerticalAlign",
          "tableCellHorizontalAlign",
          "tableCellStyle",
        ],
        tableInsertHelperOffset: 15,
        tableInsertMaxSize: 10,
        tableMultipleStyles: true,
        tableResizerOffset: 5,
        tableResizer: true,
        tableResizingLimit: 30,
        language: "id",
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              // "fontFamily",
              "fontSize",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "inlineStyle",
              "clearFormatting",
            ],
          },
          moreParagraph: {
            buttons: [
              "alignLeft",
              "alignCenter",
              "formatOLSimple",
              "alignRight",
              "alignJustify",
              "formatOL",
              "formatUL",
              "paragraphFormat",
              "paragraphStyle",
              "lineHeight",
              "outdent",
              "indent",
              "quote",
            ],
          },
          moreRich: {
            buttons: [
              "insertTable",
              "insertImage",
              // "insertFile",
              "insertVideo",
              "insertLink",
              "emoticons",
              "fontAwesome",
              "specialCharacters",
              "embedly",
              "insertHR",
            ],
          },
          moreMisc: {
            buttons: [
              "undo",
              "redo",
              "fullscreen",
              "print",
              "getPDF",
              "spellChecker",
              "selectAll",
              "html",
              "help",
            ],
          },
        },
      },
    };
  },
  mounted() {
    this.createEditor();
  },
  computed: {
    editorData: {
      get() {
        return this.oldEditorData;
      },
      set(e) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$emit("editorData", e);
          this.$emit("enableAction", true);
        }, 200);
      },
    },
  },
  watch: {
    "$vuetify.breakpoint.smAndDown": {
      handler: function (newVal) {
        if (newVal == false) {
          this.setExpandOnHover(false);
        }
      },
      deep: true,
    },
    editorDisabled(val) {
      if (val == true) {
        this.disableEditor();
      } else {
        this.enableEditor();
      }
    },
  },
  methods: {
    ...mapActions(["setExpandOnHover"]),
    axiosOnProgress(val) {
      this.$emit("froalaIsWorking", val);
    },
  },
};
</script>
<style lang="scss">
.revision-editor-container .fr-wrapper {
   margin: 0 auto;
}
.fr-toolbar,
.fr-wrapper,
.fr-second-toolbar {
  border: none !important;
}
.fr-popup.fr-desktop.fr-active {
  z-index: 1040 !important;
}
.black-border thead tr th,
.black-border tbody tr td {
  border-collapse: collapse;
  border: 1px solid black;
}
.white-border thead tr th,
.white-border tbody tr td {
  border-collapse: collapse;
  border: 1px solid white;
}
.frbox {
  width: 100% !important;
}
.fr-box.fr-basic {
  width: 100% !important;
  height: 100%;
}
</style>